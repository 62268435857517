






































































import QrCodeDialog from "./QrCodeDialog.vue";
// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: { QrCodeDialog }
})
export default class ApproveAgentDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dialogAaVisible!: boolean;
  @Provide() currentType:any = -1;
  @Prop(Array) agentList!:any;
  @Provide() pay_type_visible: boolean = false;
  @Provide() pay_type: any = 0;
  // 是否打开付款二维码页面
  @Provide() dgQrCodeVisible: boolean = false;
  // 支付二维码数据
  @Provide() qrCodeData: any = {
    order_sn: '',
    pay_logo: '',
    pay_type_title: '',
    qrcode_url: '',
    order_amount: ''
  }
  // 会员信息
  @Prop(Object) memberInfo!: {};
  // 配置信息
  @Prop(Object) config!: {};
  @Provide() payTimer:any = null;
  created() {
    // this.init();
  }
  // 初始化
  init() {
    let p = {
      m: "user.getAgentList"
    };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      p,
      (res: any) => {
        if (res.data.code === 0) {
          this.agentList = res.data.data.agent_list;
        } else {
          this.agentList = [];
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  buttonType(index:any){
    if(index === this.currentType){
      return "success"
    }else{
      return "danger"
    }
  }
  selectMoney(index: any) {
    this.currentType = index;
    let agent_price = this.agentList[index].vip_price;
    this.pay_type_visible = agent_price > 0 ? true : false;

    this.buttonType(index);
  }
  //切换支付方式
  togglePay(index: any) {
    if (this.pay_type == index) {
      return;
    }
    this.pay_type = index;
  }
  submitForm(formName: any) {
    let utils = (this as any).$utils;
    let forms: any = {
      m: "user.createAgentOrder"
    };
    if(this.currentType < 0) {
      this.$message({
        message: '请选择要开通的代理等级',
        type: "error",
        showClose: true
      });
      return;
    }
    if(this.pay_type_visible == true && this.pay_type <= 0) {
      this.$message({
        message: '请选择支付方式',
        type: "error",
        showClose: true
      });
      return;
    }
    // 套餐ID
    forms.vip_id = this.agentList[this.currentType].vip_id;
    forms.pay_type = this.pay_type;
    forms.order_ori = 1;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let order_info = res.data.data;
            if(order_info.pay_status == 1) {
              this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
            setTimeout(() => {
              this.$emit("closeAaDialog");
              this.$emit("loadMember");
              this.$emit("loadAgentList");
            }, 2000);
          } else {
            this.qrCodeData.order_sn = order_info.order_sn;
            this.qrCodeData.pay_logo = order_info.pay_logo;
            this.qrCodeData.pay_type_title = order_info.pay_type_title;
            this.qrCodeData.qrcode_url = order_info.qrcode_url;
            this.qrCodeData.order_amount = order_info.order_amount;
            this.$emit("closeAaDialog");
            let qr_type = res.data.data.qr_type;
            if(qr_type == 'codepay' ||
              qr_type == 'yipay' || 
              qr_type == 'wxh5') {
                window.location.href = this.qrCodeData.qrcode_url;
                return;
            }

            this.dgQrCodeVisible = true;
            this.payTimer = setInterval(() => {
                    this.getPayOrder(this.qrCodeData.order_sn);
                },2000);
          }
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
          if(res.data.code === 2) {
            this.$router.push('/');
          }
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 关闭付款二维码对话框页面
  closeQrCodeDialog() {
    this.dgQrCodeVisible = false;
    if(this.payTimer != null) {
      clearInterval(this.payTimer);
      this.payTimer = null;
    }
  }

  getPayOrder(order_sn: String) {
    let forms: any = {};
    forms.m = 'user.getAgentPayOrder';
    forms.order_sn = order_sn;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let pay_status = res.data.data.pay_status;
              if(pay_status == 1) {
                if(this.payTimer != null) {
                    clearInterval(this.payTimer);
                    this.payTimer = null;
                }
                this.$message({
                    showClose: true,
                    message: '支付成功',
                    type: 'success'
                });
                setTimeout(() => {
                    this.dgQrCodeVisible = false;
                    this.$emit("loadMember");
                }, 2000);
              }
          } else {
              if(this.payTimer != null) {
                clearInterval(this.payTimer);
                this.payTimer = null;
              }
              this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
              });
          }
      },
      (err: any) => {
          console.log(err);
      },
      false
    );
  }
}
