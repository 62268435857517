





































// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: {}
})
export default class WithDrawDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dialogWdVisible!: boolean;
  @Prop(Object) withDrawData!: {
    wd_amount: String;
    wd_money: String;
    wd_type_id: String;
    pay_type: String;
    pay_type_name: String;
    user_name: String;
    user_account: String;
    receipts_qrcode: String;
    receipts_qrcode_url: String;
    wdmin: String;
    wdmax: String;
  };
  @Provide() wd_money: any = 0;
  @Provide() wd_rate_fee: any = 0;
  @Provide() ad_pos_list: any = [];
  @Provide() wd_config: any = [];
  @Provide() rules: any = {
    wd_money: [
      { required: true, message: "请输入提现金额", trigger: "blur" }
    ]
  };
  created() {
    //this.initConfig();
  }
  mounted() {
    
  }
  submitForm(formName: any) {
    let utils = (this as any).$utils;

    let forms = {
      m: "withDraw.doWithDraw"
    };
    this.withDrawData.wd_money = this.wd_money;
    forms = Object.assign(forms,this.withDrawData);
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        (this as any).$ajax.httpPost(
          "/api/api/router",
          forms,
          (res: any) => {
            if (res.data.code === 0) {
              // this.$emit("closeDialog");
              this.$emit("loadMember");
              this.$message({
                message: res.data.msg,
                type: "success"
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error"
              });
            }
          },
          (err: any) => {
            console.log(err);
          }
        );
      }
    });
  }
  get wdmoney() {
    return this.wd_money;
  }
  set wdmoney(value:any) {
    this.wd_config = this.$store.state.config;
    this.wd_money = value;
		let wd_rate_fee = ((parseFloat(value) * parseFloat(this.wd_config.wdrate)) / 100).toFixed(2);
		this.wd_rate_fee = wd_rate_fee;
  }

}
