





































// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: {  }
})
export default class ViewStationDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dialogViewAsVisible!: boolean;
  @Prop(String) sub_id: String = '';
  @Prop(String) domain_url: String = '';
  @Provide() domain_name: String = '';
  
  // 配置信息
  @Prop(Object) config!: {};
  created() {
  }
  submitForm(formName: any) {
    let utils = (this as any).$utils;
    let forms: any = {
      m: "user.modifySubDomain"
    };
    if(this.domain_name == '') {
      this.$message({
        message: '请输入域名',
        type: "error",
        showClose: true
      });
      return;
    }
    // 套餐ID
    forms.sub_id = this.sub_id;
    forms.domain_name = this.domain_name;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
          this.$emit("closeViewAsDialog");
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  onCopy(e:any) {
    this.$message({
      showClose: true,
      message: '复制成功',
      type: 'success'
    });
  }
  onError(e:any) {
    this.$message({
      showClose: true,
      message: '复制失败',
      type: 'error'
    });
  }
}
