





























































import QrCodeDialog from "./QrCodeDialog.vue";
// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: { QrCodeDialog }
})
export default class MemberChargeDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dialogMcVisible!: boolean;
  // @Prop(Object) configList!: {
  // };
  // 配置信息
  @Prop(Object) config!: {};
  @Provide() currentType:any = 1;
  @Provide() configList:any = [];
  @Provide() cust_money: String = '';
  @Provide() pay_type: any = 0;
  // 是否打开付款二维码页面
  @Provide() dgQrCodeVisible: boolean = false;
  // 支付二维码数据
  @Provide() qrCodeData: any = {
    order_sn: '',
    pay_logo: '',
    pay_type_title: '',
    qrcode_url: '',
    order_amount: ''
  }
  @Provide() payTimer:any = null;
  created() {
    this.init();
  }
  // 初始化
  init() {
    let p = {
      m: "user.getChargeConfig"
    };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      p,
      (res: any) => {
        if (res.data.code === 0) {
          this.configList = res.data.data.config_list;
        } else {
          this.configList = [];
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  buttonType(index:any){
    if(index === this.currentType){
      return "success"
    }else{
      return "danger"
    }
  }
  selectMoney(index: any) {
    this.currentType = index;
    this.buttonType(index);
    //console.log('m',this.configList[index]);
  }
  //切换支付方式
  togglePay(index: any) {
    if (this.pay_type == index) {
      return;
    }
    this.pay_type = index;
  }
  submitForm(formName: any) {
    let utils = (this as any).$utils;
    let forms: any = {
      m: "user.createChargeOrder"
    };
    // 自定义金额
    let cust_money = utils.praseStrEmpty(this.cust_money);
    if(cust_money == '') {
      cust_money = this.configList[this.currentType].ch_money;
    } else {
      cust_money = parseFloat(cust_money);
    }
    if(isNaN(cust_money) || cust_money < 0) {
      this.$message({
        message: '请输入正确的充值金额',
        type: "error",
        showClose: true
      });
      return;
    }
    if(this.pay_type <= 0) {
      this.$message({
        message: '请选择支付方式',
        type: "error",
        showClose: true
      });
      return;
    }
    forms.charge_money = cust_money;
    forms.pay_type = this.pay_type;
    forms.order_ori = 1;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          //this.$emit("loadData");
          this.qrCodeData.order_sn = res.data.data.order_sn;
          this.qrCodeData.pay_logo = res.data.data.pay_logo;
          this.qrCodeData.pay_type_title = res.data.data.pay_type_title;
          this.qrCodeData.qrcode_url = res.data.data.qrcode_url;
          this.qrCodeData.order_amount = res.data.data.order_amount;
          this.$emit("closeMcDialog");

          let qr_type = res.data.data.qr_type;
					if(qr_type == 'codepay' ||
						 qr_type == 'yipay' || 
						 qr_type == 'wxh5') {
               window.location.href = this.qrCodeData.qrcode_url;
               return;
					}

          this.dgQrCodeVisible = true;
          this.payTimer = setInterval(() => {
                  this.getPayOrder(this.qrCodeData.order_sn);
					    },2000);
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
          if(res.data.code === 2) {
            this.$router.push('/');
          }
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 关闭付款二维码对话框页面
  closeQrCodeDialog() {
    this.dgQrCodeVisible = false;
    if(this.payTimer != null) {
      clearInterval(this.payTimer);
      this.payTimer = null;
    }
  }

  getPayOrder(order_sn: String) {
    let forms: any = {};
    forms.m = 'user.getChargePayOrder';
    forms.order_sn = order_sn;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let pay_status = res.data.data.pay_status;
              if(pay_status == 1) {
                if(this.payTimer != null) {
                    clearInterval(this.payTimer);
                    this.payTimer = null;
                }
                this.$message({
                    showClose: true,
                    message: '支付成功',
                    type: 'success'
                });
                setTimeout(() => {
                    this.dgQrCodeVisible = false;
                    this.$emit("loadMember");
                }, 2000);
              }
          } else {
              if(this.payTimer != null) {
                clearInterval(this.payTimer);
                this.payTimer = null;
              }
              this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
              });
          }
      },
      (err: any) => {
          console.log(err);
      },
      false
    );
  }
}
