



















// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: {}
})
export default class QrCodeDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dgQrCodeVisible!: boolean;
  @Prop(Object) qrCodeData!: {
    order_sn: String;
    pay_logo: any;
    pay_type_title: String;
    qrcode_url: String;
    order_amount: String;
  };
  
  created() {
    console.log('qrcode dialog');
  }
  mounted() {
    console.log('mounted init');
  }
}
