







































































































































































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import ViewCardDialog from "../../components/ViewCardDialog.vue";
import WithDrawDialog from "../../components/WithDrawDialog.vue";
import MemberChargeDialog from "../../components/MemberChargeDialog.vue";
import ApproveStationDialog from "../../components/ApproveStationDialog.vue";
import ApproveAgentDialog from "../../components/ApproveAgentDialog.vue";
import ViewStationDialog from "../../components/ViewStationDialog.vue";
import CardQrCodeDialog from "../../components/CardQrCodeDialog.vue";
@Component({
  components: { LayoutHeader, LayoutFooter, ViewCardDialog, WithDrawDialog, MemberChargeDialog, ApproveStationDialog, ApproveAgentDialog,ViewStationDialog, CardQrCodeDialog },
})
export default class Default extends Vue {
  // 是否打开提现页面
  @Provide() dialogWdVisible: boolean = false;
  // 是否打开充值页面
  @Provide() dialogMcVisible: boolean = false;
  // 是否打开分站申请页面
  @Provide() dialogAsVisible: boolean = false;
  // 是否打开会员等级申请页面
  @Provide() dialogAaVisible: boolean = false;
  // 是否订单卡密页面
  @Provide() dgCardViewVisible: boolean = false;
  // 是否打开卡密二维码页面
  @Provide() dgCardQrCodeVisible: boolean = false;
  // 是否打开查看分站页面
  @Provide() dialogViewAsVisible: boolean = false;
  // 提现数据
  @Provide() withDrawData: any = {
    wd_amount: '0',
    wd_money: '0',
    wd_type_id: '',
    pay_type: '',
    pay_type_name: '',
    user_name: '',
    user_account: '',
    receipts_qrcode: '',
    receipts_qrcode_url: '',
    wdmin: 10,
    wdmax: 100
  };
  @Provide() member_info: any = [];
  @Provide() order_info: any = [];
  @Provide() order_list: any = [];
  @Provide() memberInfo: any = {
    money: 0,
    vip_id: 0,
  };
  @Provide() agentList: any = [];
  @Provide() vip_upgrade_flg: boolean = false;
  @Provide() config: any = {};
  // 分站信息
  @Provide() sub_id: String = "0";
  @Provide() domain_url: String = "";
  // 订单页面内容
  @Provide() viewCardData: any = {
      order_info: [],
      card_list: [],
      card_content: ""
  };
  created() {
    let utils = (this as any).$utils;
    let opt_type = utils.praseStrEmpty(this.$route.query.opt_type);
    let order_sn = utils.praseStrEmpty(this.$route.query.out_trade_no);
    
    if(opt_type == 'charge') {
      this.getCharePayOrder(order_sn);
		} else if(opt_type == 'station') {
			this.getComboPayOrder(order_sn);
		} else if (opt_type == 'agent') {
			this.getAgentPayOrder(order_sn);
		}

    this.loadMember();
    this.loadAgentList();
  }
  // 初始化信息
  loadData(){
    if( this.dialogMcVisible == true) {
      this.dialogMcVisible = false;
    }
  }
  // 加载会员信息
  loadMember() {
    this.dialogAsVisible = false;
    this.dialogWdVisible = false;
    let forms = {
        m: "user.getMember",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.member_info = res.data.data.member_info;
          this.order_info = res.data.data.order_info;
          this.order_list = res.data.data.order_list;

          this.memberInfo.money = this.member_info.money;
          this.memberInfo.vip_id = this.member_info.vip_id;

          this.config = this.$store.state.config;
        } else if(res.data.code === 2) {
          localStorage.setItem('tsToken',"");
          this.$message({
            showClose: true,
            message: '登录超时，请重新登录',
            type: 'error'
          });
          setTimeout(()=>{
            this.$router.push("/");
          },2000);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 加载代理等级
  loadAgentList() {
    this.vip_upgrade_flg = false;
    let p = {
      m: "user.getAgentList"
    };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      p,
      (res: any) => {
        if (res.data.code === 0) {
          let agentList = res.data.data.agent_list;
          this.agentList = agentList;
          let agList:any = [];
          agentList.forEach((item:any,index:Number) => {
            agList.push(item);
            if(item.disabled == 0) {
              this.vip_upgrade_flg = true;
            }
          })
          // this.agentList = agList;
        } else {
          this.agentList = [];
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  navMyOrder(order_type:Number){
    this.$router.push({ name: 'myOrder', query: { order_type: String(order_type) }});
  }
  // 继续付款
  goPay(order_sn:String,pay_type:String) {
    let utils = (this as any).$utils;
    pay_type = utils.praseStrEmpty(pay_type);
    if(pay_type == "") {
      this.$router.push({ name: 'order', query: { sn: String(order_sn) }});
    } else {
      this.$router.push({ name: 'pay', query: { sn: String(order_sn) ,pay_type: String(pay_type) }});
    }
  }

  // 提现
  withdraw() {
    let utils = (this as any).$utils;
    let forms = {
        m: "withDraw.getWithDrawInfo"
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let withdraw = res.data.data.withdraw;
          console.log('config',this.config);
          // 可提现金额
          this.withDrawData.wd_amount = withdraw.wd_amount;
          this.withDrawData.wd_money = withdraw.wd_amount;
          this.withDrawData.wd_type_id = withdraw.wd_type_id;
          this.withDrawData.pay_type = withdraw.pay_type;
          this.withDrawData.pay_type_name = withdraw.pay_type_name;
          this.withDrawData.user_name = withdraw.user_name;
          this.withDrawData.user_account = withdraw.user_account;
          this.withDrawData.receipts_qrcode = withdraw.receipts_qrcode;
          this.withDrawData.receipts_qrcode_url = withdraw.receipts_qrcode_url;
          this.withDrawData.wdmin = utils.praseStrEmpty(this.config.wdmin) != '' ? this.config.wdmin : '10';
          this.withDrawData.wdmax = utils.praseStrEmpty(this.config.wdmax) != '' ? this.config.wdmax : '100';
          this.dialogWdVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
          if(res.data.code === 2) {
            this.$router.push('/');
          }
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  // 充值
  charge() {
    this.dialogMcVisible = true;
  }

  // 关闭对话框页面
  closeWdDialog() {
    this.dialogWdVisible = false;
  }

  // 关闭充值对话框页面
  closeMcDialog() {
    this.dialogMcVisible = false;
  }

  // 查看卡密
  orderDetail(order_sn:String) {
    let forms = {
        m: "user.orderDetail",
        order_sn: order_sn
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let order_info = res.data.data.order_info;
          let card_list = order_info.card_list;
          this.viewCardData.order_info = order_info;
          this.viewCardData.card_list = card_list;
          let card_content = '';
          card_list.forEach((card:any,index:number) => {
            card_content += card.card_pass + '\r\n';
          })
          this.viewCardData.card_content = card_content;
          this.dgCardViewVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 再次购买
  reBuy(goods_id:String) {
    this.$router.push({ name: 'goods', query: { id: String(goods_id) }});
  }
  // 关闭卡密页面
  closeViewCardDialog() {
    this.dgCardViewVisible = false;
  }
  // 查看卡密二维码 
  viewCardQrDialog() {
    this.dgCardViewVisible = false;
    this.dgCardQrCodeVisible = true;
  }
  // 关闭卡密二维码页面 
  closeCardQrDialog() {
    this.dgCardQrCodeVisible = false;
  }
  // 申请分站
  approveStation() {
    this.dialogAsVisible = true;
  }
  // 关闭分站页面
  closeAsDialog() {
    this.dialogAsVisible = false;
  }
  // 关闭查看分站页面
  closeViewAsDialog() {
    this.dialogViewAsVisible = false;
  }
  // 查看分站域名
  ViewStation() {
    let forms = {
        m: "user.getMemberDomain"
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.sub_id = data.sub_id;
          this.domain_url = data.domain_url;
          this.dialogViewAsVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 申请代理
  approveAgent() {
    this.dialogAaVisible = true;
  }
  // 关闭会员申请页面
  closeAaDialog() {
    this.dialogAaVisible = false;
  }
  // 获取充值订单
  getCharePayOrder(order_sn: String) {
    let forms: any = {};
    forms.m = 'user.getChargePayOrder';
    forms.order_sn = order_sn;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let pay_status = res.data.data.pay_status;
              let t:any = new Date().getTime();
              if(pay_status == 1) {
                this.$alert('充值成功', '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
                });
              } else {
                this.$alert('充值失败', '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
                });
              }
          } else {
              this.$alert(res.data.msg, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
              });
          }
      },
      (err: any) => {
          console.log(err);
      },
      false
    );
  }
  // 获取代理开通订单
  getAgentPayOrder(order_sn: String) {
    let forms: any = {};
    forms.m = 'user.getAgentPayOrder';
    forms.order_sn = order_sn;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let pay_status = res.data.data.pay_status;
              if(pay_status == 1) {
                this.$alert('开通成功', '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
                });
              } else {
                this.$alert('开通失败', '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
                });
              }
          } else {
              this.$alert(res.data.msg, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
              });
          }
      },
      (err: any) => {
          console.log(err);
      },
      false
    );
  }
  // 获取分站开通订单
  getComboPayOrder(order_sn: String) {
    let forms: any = {};
    forms.m = 'user.getComboPayOrder';
    forms.order_sn = order_sn;
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let pay_status = res.data.data.pay_status;
              if(pay_status == 1) {
                this.$alert('开通成功', '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
                });
              } else {
                this.$alert('开通失败', '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
                });
              }
          } else {
              this.$alert(res.data.msg, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$router.push('/default');
                  }
              });
          }
      },
      (err: any) => {
          console.log(err);
      },
      false
    );
  }
}
